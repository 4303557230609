import './resumePage.scss';
import ResumeComponent from '../../components/Resume/resume';
import { ShortBio } from '../../components/Bio/bio';
import Quote from '../../components/Quote/quote';

function ResumePage(){

    return (
    <>
        <Quote 
            quote="I want to be the very best, like no one ever was.."
            author="Jason Paige"
        />
        <div className="resumePage-container">
            <ResumeComponent />
            <ShortBio />
        </div>
    </>)
}

export default ResumePage;