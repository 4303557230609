import './nopage.scss'

function Nopage(){

    return (
        <div className="nopage-wrapper" aria-label="No Page Found">
            No Page
        </div>
        
    );  
}

export default Nopage;