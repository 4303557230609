import './contact.scss';
import { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';

// Contact Component

function ContactComponent() {
    const [success, showSuccess] = useState(false);
    const [warning, showWarning] = useState(false);
    const [nameNote, showNameNote] = useState(false);
    const [emailNote, showEmailNote] = useState(false);
    const [emailFormat, showEmailFormat] = useState(false);
    const [phoneNote, showPhoneNote] = useState(false);
    const [phoneFormat, showPhoneFormat] = useState(false);
    const [subjectNote, showSubjectNote] = useState(false);
    const [messageNote, showMessageNote] = useState(false);
    const formRef = useRef();
    const name = document.getElementById('p-name')
    const email = document.getElementById('p-email')
    const phone = document.getElementById('p-phone')
    const subject = document.getElementById('p-subject')
    const message = document.getElementById('p-message')
    const SuccessNote = () => {
        return (
            <p className='contact-note'>Your messsage was sent successfully! Please be patient and I will contact you as soon as I can.</p>
        );
    };
    const WarningNote = () => {
        return (
            <p className='contact-note'>Uh-oh,Something went wrong!</p>
        );
    };
    const [errorList, setErrorList] = useState([]);
    const isValidEmail = (emailVal) => {
        // Regular expression for basic email validation
        let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
        if (!emailRegex.test(emailVal.value)) {
            console.log('Email invalid: ' + emailVal.value)
            return 0;
        } else {
            console.log('Email valid')
            return 1;
        }
        // const emailRegex = /A\S+@\5+ 1. \S I$/;
        // return emailRegex.test (emailVal);
        // return 1;
    }
    const isvalidPhoneNumber = (phoneNumberVal) => {
        // Regular expression for basic phone number validation (10 digits)
        let phoneRegex = /^\d{10}$/;
        if (!phoneRegex.test(phoneNumberVal.value)) {
            console.log('Phone invalid: ' + phoneNumberVal.value)
            return 0;
        } else {
            console.log('Phone valid')
            return 1;
        }
    }
    const validateForm = () => {
        let newErrors = [];

        if(!name || name === null ){
            showNameNote(true);
            newErrors.push('Your name is required');
            return 0;
        }
        if(!subject){
            showSubjectNote(true);
            newErrors.push('A subject is required');
            return 0;
        }
        if(!message){
            showMessageNote(true);
            newErrors.push('A message is required');
            return 0;
        }
        if(!email){
            showEmailNote(true);
            newErrors.push('Your email is required');
            return 0;
        } else if(!isValidEmail(email)){
            showEmailFormat(true);
            newErrors.push('Invalid Email format');
            return 0;
        }
        if(!phone){
            showPhoneNote(true);
            newErrors.push('Your phone number is required');
            return 0;
        } else if(!isvalidPhoneNumber(phone)){
            showPhoneFormat(true);
            newErrors.push('Invalid Phone number format');
            return 0;
        }

        setErrorList(newErrors)
        console.log(errorList)
        return 1;
    }
    const sendEmail = (e) => {
        e.preventDefault();
        showWarning(false)
        const isValid = validateForm();

        if (isValid){
            emailjs
            .sendForm('service_cpqucqf', 'template_6es837l', formRef.current, {
                publicKey: 'gatR3NM9L9fe9y0uX',
            })
            .then(
                (success) => {
                    console.log(success.text);
                    e.target.reset();
                    showSuccess(true);
                    setTimeout(() => {
                        showSuccess(false)
                    }, 5000);
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );  
        } else {
            console.log('Not valid');
            showWarning(true);
        }
        
    };


    return(
        <div className="contact-c-container">
            <h3 className='contact-c-header'>Contact Me</h3>
            <form ref={formRef} onSubmit={sendEmail} className='contact-c-form' id='p-form' >
                <div className='contact-c-form-div'>
                    <div className='contact-c-input-wrapper'>
                        <input type='text' name="user_name" placeholder='Full Name' id='p-name' className='contact-c-input-field' autocomplete='on'/>
                        {nameNote ? <p className='contact-note'>Your name is required</p> : ''}
                    </div>
                    <div className='contact-c-input-wrapper'>
                        <input type='email 'name="user_email"  placeholder='Email Address' id='p-email' className='contact-c-input-field' autocomplete='on'/>
                        {emailNote ? <p className='contact-note'>Your email address is required</p> : ''}
                        {emailFormat ? <p className='contact-note'>Invalid email address</p> : ''}
                    </div>
                </div>
                <div className='contact-c-form-div'>
                    <div className='contact-c-input-wrapper'>
                        <input type='tel' name="user_phone" placeholder='Phone Number' id='p-phone' className='contact-c-input-field' autocomplete='on'/>
                        {phoneNote ? <p className='contact-note'>A phone number is required</p> : ''}
                        {phoneFormat ? <p className='contact-note'>Invalid phone number</p> : ''}
                    </div>
                    <div className='contact-c-input-wrapper'>
                        <input type='text' name="message_subject" placeholder='Subject' id='p-subject' className='contact-c-input-field' autocomplete='off'/>
                        {subjectNote ? <p className='contact-note'>A subject is required</p> : ''}
                    </div>
                </div>
                <div className='contact-c-textarea-wrapper'>
                    {messageNote ? <p className='contact-note'>A message is required</p> : ''}
                    <textarea 
                        name="message" 
                        placeholder='Enter Your Message Here' 
                        id='p-message' 
                        className='contact-c-textarea-field' 
                        autocomplete='off'
                    ></textarea>
                </div>
                <button type='submit' className='contact-c-submit'>Submit Message</button>
                {success ? <SuccessNote /> : ''}
                {warning ? <WarningNote /> : ''}
            </form>
        </div>
    )
}


// Contact Page

function ContactPage() {
    const [success, showSuccess] = useState(false);
    const [warning, showWarning] = useState(false);
    const [nameNote, showNameNote] = useState(false);
    const [emailNote, showEmailNote] = useState(false);
    const [emailFormat, showEmailFormat] = useState(false);
    const [phoneNote, showPhoneNote] = useState(false);
    const [phoneFormat, showPhoneFormat] = useState(false);
    const [subjectNote, showSubjectNote] = useState(false);
    const [messageNote, showMessageNote] = useState(false);
    const navigate = useNavigate();    
    const formRef = useRef();
    const name = document.getElementById('p-name')
    const email = document.getElementById('p-email')
    const phone = document.getElementById('p-phone')
    const subject = document.getElementById('p-subject')
    const message = document.getElementById('p-message')
    const SuccessNote = () => {
        return (
            <p className='contact-note'>Your messsage was sent successfully! Please be patient and I will contact you as soon as I can.</p>
        );
    };
    const WarningNote = () => {
        return (
            <p className='contact-note'>Uh-oh,Something went wrong!</p>
        );
    };
    const [errorList, setErrorList] = useState([]);
    const isValidEmail = (emailVal) => {
        // Regular expression for basic email validation
        let emailRegex = /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/;
        if (!emailRegex.test(emailVal.value)) {
            console.log('Email invalid: ' + emailVal.value)
            return 0;
        } else {
            console.log('Email valid')
            return 1;
        }
        // const emailRegex = /A\S+@\5+ 1. \S I$/;
        // return emailRegex.test (emailVal);
        // return 1;
    }
    const isvalidPhoneNumber = (phoneNumberVal) => {
        // Regular expression for basic phone number validation (10 digits)
        let phoneRegex = /^\d{10}$/;
        if (!phoneRegex.test(phoneNumberVal.value)) {
            console.log('Phone invalid: ' + phoneNumberVal.value)
            return 0;
        } else {
            console.log('Phone valid')
            return 1;
        }
    }
    const validateForm = () => {
        let newErrors = [];

        if(!name || name === null ){
            showNameNote(true);
            newErrors.push('Your name is required');
            return 0;
        }
        if(!subject){
            showSubjectNote(true);
            newErrors.push('A subject is required');
            return 0;
        }
        if(!message){
            showMessageNote(true);
            newErrors.push('A message is required');
            return 0;
        }
        if(!email){
            showEmailNote(true);
            newErrors.push('Your email is required');
            return 0;
        } else if(!isValidEmail(email)){
            showEmailFormat(true);
            newErrors.push('Invalid Email format');
            return 0;
        }
        if(!phone){
            showPhoneNote(true);
            newErrors.push('Your phone number is required');
            return 0;
        } else if(!isvalidPhoneNumber(phone)){
            showPhoneFormat(true);
            newErrors.push('Invalid Phone number format');
            return 0;
        }

        setErrorList(newErrors)
        console.log(errorList)
        return 1;
    }
    const sendEmail = (e) => {
        e.preventDefault();
        showWarning(false)
        const isValid = validateForm();

        if (isValid){
            emailjs
            .sendForm('service_cpqucqf', 'template_6es837l', formRef.current, {
                publicKey: 'gatR3NM9L9fe9y0uX',
            })
            .then(
                (success) => {
                    console.log(success.text);
                    e.target.reset();
                    showSuccess(true);
                    setTimeout(() => {
                        showSuccess(false)
                        navigate('/');
                    }, 5000);
                },
                (error) => {
                    console.log('FAILED...', error.text);
                },
            );  
        } else {
            console.log('Not valid');
            showWarning(true);
        }
        
    };


    return(
        <div className="contact-p-container">
            <h1 className='contact-p-header'>Contact Page</h1>
            <form ref={formRef} onSubmit={sendEmail} className='contact-p-form' id='p-form' >
                <div className='contact-p-form-div'>
                    <div className='contact-p-input-wrapper'>
                        <label className='contact-p-input-label'>Name</label>
                        <input type='text' name="user_name" placeholder='Full Name' id='p-name' className='contact-p-input-field' autocomplete='on'/>
                        {nameNote ? <p className='contact-note'>Your name is required</p> : ''}
                    </div>
                    <div className='contact-p-input-wrapper'>
                        <label className='contact-p-input-label'>Email</label>
                        <input type='email 'name="user_email"  placeholder='Email Address' id='p-email' className='contact-p-input-field' autocomplete='on'/>
                        {emailNote ? <p className='contact-note'>Your email address is required</p> : ''}
                        {emailFormat ? <p className='contact-note'>Invalid email address</p> : ''}
                    </div>
                </div>
                <div className='contact-p-form-div'>
                    <div className='contact-p-input-wrapper'>
                        <label className='contact-p-input-label'>Phone</label>
                        <input type='tel' name="user_phone" placeholder='Phone Number' id='p-phone' className='contact-p-input-field' autocomplete='on'/>
                        {phoneNote ? <p className='contact-note'>A phone number is required</p> : ''}
                        {phoneFormat ? <p className='contact-note'>Invalid phone number</p> : ''}
                    </div>
                    <div className='contact-p-input-wrapper'>
                        <label className='contact-p-input-label'>Subject</label>
                        <input type='text' name="message_subject" placeholder='Subject' id='p-subject' className='contact-p-input-field' autocomplete='off'/>
                        {subjectNote ? <p className='contact-note'>A subject is required</p> : ''}
                    </div>
                </div>
                <div className='contact-p-textarea-wrapper'>
                    {messageNote ? <p className='contact-note'>A message is required</p> : ''}
                    <textarea 
                        name="message" 
                        placeholder='Enter Your Message Here' 
                        id='p-message' 
                        className='contact-p-textarea-field' 
                        autocomplete='off'
                    ></textarea>
                </div>
                <button type='submit' className='contact-p-submit'>Submit Message</button>
                {success ? <SuccessNote /> : ''}
                {warning ? <WarningNote /> : ''}
            </form>
        </div>
    )
}

export {ContactPage, ContactComponent};