import './jobs.scss'
import {useState} from 'react';
import Quote from '../Quote/quote';
import PDLogo from '../../assets/images/w3clear.png';

function Jobs() {

    const [imageIndex, setImageIndex] = useState(0);

    const jobInfo = [
        {
            key: 1,
            name: "Power Distributors",
            title: "Front-End Developer",
            location: "Columbus, OH",
            length: "November 2021 to Present",
            description: "N/A",
            image: {
                url: `${PDLogo}`,
                alt: "Power Distributors website"
            }
        },
        {
            key: 2,
            name: "W3 Stop",
            title: "Owner & Lead Dev",
            location: "Columbus, OH",
            length: "May 2022 to Present",
            description: "N/A",
            image: {
                url: "",
                alt: ""
            }
        },
        {
            key: 3,
            name: "Innovative Healthcare Institute",
            title: "Wordpress Developer",
            location: "Cincinnati, OH",
            length: "April 2021 to February 2023",
            description: "N/A",
            image: {
                url: "",
                alt: ""
            }
        },
        {
            key: 4,
            name: "Unique Wholesale ATL",
            title: "Spotify Developer",
            location: "Norcross, GA",
            length: "March 2020 to July 2020",
            description: "N/A",
            image: {
                url: "",
                alt: ""
            }
        },
        {
            key: 5,
            name: "Free Gaming Deals",
            title: "Shopify Admin",
            location: "Atlanta, GA",
            length: "April 2018 to January 2020",
            description: "N/A",
            image: {
                url: "",
                alt: ""
            }
        },
        {
            key: 6,
            name: "Self Employed",
            title: "Digital Marketer",
            location: "Atlanta, GA",
            length: "October 2017 to May 2018",
            description: "N/A",
            image: {
                url: "",
                alt: ""
            }
        }
    ]

    function showNextImage() {
        setImageIndex(index => {
            if(index === jobInfo.length - 1) {
                return 0;
            } else {
               return index + 1;
            }
        })
        
        
    };

    function showPrevImage() {
        setImageIndex(index => {
            if(index === 0) {
                return jobInfo.length - 1;
            } else {
            return index - 1;
            }
        })
        
        
    };

    return (<>
        <Quote/>
        <section className="jobs-wrapper" aria-label="Web Projects Slider">
            <a href="#after-jobs" className='skip-link'>
                Skip Web Projects Section
            </a>
            <div className="jobs-container">
            {   jobInfo.map((job, key, index ) => {
                return (
                    <div className="job-card" key={job.key} style={{translate: `${-100 * imageIndex}%`}}>
                        <div className="job-card-top">
                            {job.image.length !== 0 ? <img src={`${job.image.url}`} className="job-card-img" alt={`${job.image.alt}`} key={job.key} aria-hidden={imageIndex !== job.key}/> : "No Image"}
                        </div>
                        <div className="job-card-bottom">
                            <h3 className="job-name">{job.name}</h3>
                            <p className='job-title'>{job.title}</p>
                            <p className='job-location'>{job.location}</p>
                            <p className='job-length'>{job.length}</p>
                            <p className='job-desc'>{job.description}</p>
                            <button className='job-infoBtn' aria-label={`More Info on ${job.name}`}>More Info</button>
                        </div>
                        
                    </div>
                );
            })}
            </div>
            
            
            <i className="fa-solid fa-circle-chevron-left jobs-arrow jobs-arrow-left" onClick={showPrevImage} aria-label="View Previous Image"></i>
            <div className="jobs-nav">
                { jobInfo.map((_, index) => (
                    <button key={index} className='jobs-nav-btn' onClick={() => setImageIndex(index)} aria-label={`View Project ${index + 1}`}>
                        {
                            index === imageIndex ? <i className="fa-regular fa-circle jobs-nav-icon-selected"></i> : <i className="fa-regular fa-circle jobs-nav-icon"></i>
                        }
                    </button>
                    
                ))}
            </div>
            <i className="fa-solid fa-circle-chevron-right jobs-arrow jobs-arrow-right" onClick={showNextImage} aria-label="View Next Image"></i>
            <div id='after-jobs'/>
        </section>
    </>
        
    );
}

export default Jobs;