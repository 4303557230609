import './index.scss';


function Home() {
    return (
        <>
            <h1 className='home-container'>Home</h1>
        </>
    );
}

export default Home;