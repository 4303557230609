import './App.scss';
import {Navigation} from './components/Navigation/navigation';
import Banner from './components/Banner/banner';
import Footer from "./components/Footer/footer";
import {Routes, Route} from 'react-router-dom';
import Home from './Pages/Home/home';
import Projects from './Pages/Projects/projects';
import Music from './Pages/Music/music';
import Contact from './Pages/Contact/contact';
import ResumePage from './Pages/Resume/resumePage';
import Nopage from './Pages/Nopage/nopage';

function App() {

  return (
    <>
      <Navigation/>
      <Banner />
      <div className='animated-bkg-div'>
        <Routes>
          <Route index element={<ResumePage/>}/>
          <Route path="/home" element={<Home />}/>
          <Route path="/projects" element={<Projects />}/>
          <Route path="/music" element={<Music />}/>
          <Route path="/contact" element={<Contact />}/>
          <Route path="/resume" element={<ResumePage />}/>
          <Route path="*" element={<Nopage />}/>
        </Routes>
      </div>
      
      <Footer/>
    </>
  );
}

export default App;
