import MusicComponent from '../../components/Music/music'

function Music(){

    return (
        <>
            <MusicComponent />
        </>
    );  
}

export default Music;