import './quote.scss'

function Quote({quote = "Life has a funny way of proving you wrong in the best way possible.", author = "M.M."}){
    return (
        <div className="quote-container">
            <q className="quote-text">{quote}</q>
            <p className="quote-author">- {author}</p>
        </div>
    );
}

export default Quote;