import Jobs from '../../components/Jobs/jobs'

function Projects(){

    return (
        <>
            <Jobs />
        </>
    );  
}

export default Projects;;