import './music.scss'

function Music() {

    const latestThree = [
        {
            artist: "Makkiah",
            title: "For Years",
            date: "04/03/2024",
            genre: "Metal & Hip-hop"
        },
        {
            artist: "Makkiah",
            title: "Your High",
            date: "01/26/2024",
            genre: "Metal & Hip-hop"
        },
        {
            artist: "Makkiah",
            title: "Dark Side",
            date: "11/17/2023",
            genre: "Alternative & Hip-hop"
        },
    ]

    return (
        <div className="music-wrapper">
            <div className="music-container">
                { latestThree.map((music, key) => {
                    return (
                        <div className='music-card'>
                            <div className='music-card-top'>
                                No image
                            </div>
                            <div className='music-card-bottom'>
                                <h3 className="music-card-song-text music-card-song-title">{music.title}</h3>
                                <h4 className="music-card-song-text music-card-song-artist">{music.artist}</h4>
                                <p className="music-card-song-text music-card-song-genre">{music.genre}</p>
                                <p className="music-card-song-text music-card-song-date">{music.date}</p>
                                <button className='music-card-song-text music-card-song-btn'>Listen Now</button>
                            </div>
                        </div>
                    );
                })}
            </div>
            <button className='music-card-song-text music-card-more-btn'>More Music</button>
        </div>)
}

export default Music;