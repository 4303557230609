import Home from '../../components/Home/index';
import Quote from '../../components/Quote/quote';


function HomePage() {

  return (
    <>
      <Quote 
        quote="I want to be the very best, like no one ever was.."
        author="Jason Paige"
      />
      <Home />
    </>
  );
}

export default HomePage;