import './resume.scss';
import PDLogo from '../../assets/images/pdLogo.png';
import W3Logo from '../../assets/images/w3White.png';
import IHCILogo from '../../assets/images/ihci.jpeg';
import NoLogo from '../../assets/images/NoIMG.png';
import UHLogo from '../../assets/images/UHATL.png';


function ResumeComponent() {

    // const brandIcons = {
    //     shopify: <i className="fa-brands fa-shopify resume-card-icons" aria-label="Shopify Icon" title="Shopify"></i>,
    //     vue: <i className="fa-brands fa-vuejs resume-card-icons" aria-label="Vue JS Icon" title="Vue JS"></i>,
    //     opencart: <i className="fa-brands fa-opencart resume-card-icons" aria-label="OpenCart Icon" title="OpenCart"></i>,
    //     react: <i className="fa-brands fa-react resume-card-icons" aria-label="React JS Icon" title="React JS"></i>,
    //     javascript: <i className="fa-brands fa-js resume-card-icons" aria-label="Javascript Icon" title="Javascript"></i>,
    //     css: <i className="fa-brands fa-css3-alt resume-card-icons" aria-label="CSS3 Icon" title="CSS3"></i>,
    //     html: <i className="fa-brands fa-html5 resume-card-icons" aria-label="HTML5 Icon" title="HTML5"></i>,
    //     wordpress: <i className="fa-brands fa-wordpress resume-card-icons" aria-label="Wordpress Icon" title="Wordpress"></i>,
    //     sass: <i className="fa-brands fa-sass resume-card-icons" aria-label="Sass Icon" title="Sass"></i>,
    //     mailchimp: <i className="fa-brands fa-mailchimp resume-card-icons" aria-label="Mailchimp Icon" title="Mailchimp"></i>,
    // }

    const jobInfo = [
        {
            key: 1,
            name: "Power Distributors",
            title: "Front-End Developer",
            location: "Columbus, OH",
            length: "November 2021 to Present",
            description: "Bridged the gap between our design & development teams to update, and create, websites based on our supply chain partners & industry needs.",
            image: {
                url:  `${PDLogo}`,
                alt: "Power Distributors logo"
            },
            btnText : "View Website",
            btnLink : "https://powerdistributors.com",
            icons : [
                <i className="fa-brands fa-vuejs resume-card-icons" aria-label="Vue JS Icon" title="Vue JS"></i>,
                <i className="fa-brands fa-html5 resume-card-icons" aria-label="HTML5 Icon" title="HTML5"></i>,
                <i className="fa-brands fa-sass resume-card-icons" aria-label="Sass Icon" title="Sass"></i>
            ]
        },
        {
            key: 2,
            name: "W3 Stop",
            title: "Owner & Lead Dev",
            location: "Columbus, OH",
            length: "May 2022 to Present",
            description: "Collaborate with various small businesses, and entreprenuers, to bring attention to their brand by building their digital storefront, or hub.",
            image: {
                url:  `${W3Logo}`,
                alt: "W3 Stop Logo"
            },
            btnText : "View Website",
            btnLink : "",
            icons : [
                <i className="fa-brands fa-shopify resume-card-icons" aria-label="Shopify Icon" title="Shopify"></i>,
                <i className="fa-brands fa-wordpress resume-card-icons" aria-label="Wordpress Icon" title="Wordpress"></i>,
                <i className="fa-brands fa-sass resume-card-icons" aria-label="Sass Icon" title="SCSS"></i>,
                <i className="fa-brands fa-mailchimp resume-card-icons" aria-label="Mailchimp Icon" title="Mailchimp"></i>,
                <i className="fa-brands fa-react resume-card-icons" aria-label="React JS Icon" title="React JS"></i>
            ]
        },
        {
            key: 3,
            name: "Innovative Healthcare Institute",
            title: "Wordpress Developer",
            location: "Cincinnati, OH",
            length: "April 2021 to February 2023",
            description: "Created and updated websites for a Cincinnati, OH based healthcare journal, its subsidiaries, and IHCI annual events.",
            image: {
                url:`${IHCILogo}`,
                alt: "Innovative Healthcare Institute logo"
            },
            btnText : "View Website",
            btnLink : "https://innovativehci.com",
            icons : [
                <i className="fa-brands fa-shopify resume-card-icons" aria-label="Shopify Icon" title="Shopify"></i>,
                <i className="fa-brands fa-wordpress resume-card-icons" aria-label="Wordpress Icon" title="Wordpress"></i>,
                <i className="fa-brands fa-css3-alt resume-card-icons" aria-label="Sass Icon" title="Sass"></i>,
                <i className="fa-brands fa-html5 resume-card-icons" aria-label="HTML5 Icon" title="HTML5"></i>,
                <i className="fa-brands fa-js resume-card-icons" aria-label="Javascript Icon" title="Javascript"></i>
            ]
        },
        {
            key: 4,
            name: "Unique Wholesale ATL",
            title: "Spotify Developer",
            location: "Norcross, GA",
            length: "March 2020 to July 2020",
            description: "Made design updates, added new warehouse inventory to the website, and created marketing material as needed.",
            image: {
                url:  `${UHLogo}`,
                alt: "Unique Wholesale ATL logo"
            },
            btnText : "View Website",
            btnLink : "https://www.uniquewholesaleatl.com",
            icons : [
                <i className="fa-brands fa-shopify resume-card-icons" aria-label="Shopify Icon" title="Shopify"></i>,
                <i className="fa-brands fa-mailchimp resume-card-icons" aria-label="Mailchimp Icon" title="Mailchimp"></i>,
                <i className="fa-brands fa-opencart resume-card-icons" aria-label="OpenCart Icon" title="OpenCart"></i>,
                <i className="fa-brands fa-css3-alt resume-card-icons" aria-label="Sass Icon" title="Sass"></i>,
                <i className="fa-brands fa-html5 resume-card-icons" aria-label="HTML5 Icon" title="HTML5"></i>,
            ]
        },
        {
            key: 5,
            name: "Free Gaming Deals",
            title: "Shopify Admin",
            location: "Atlanta, GA",
            length: "April 2018 to January 2020",
            description: "Aggregated game prices across multiple affiliate partnered websites, and made it easy for users to find the latest gaming news & deals.",
            image: {
                url:  `${NoLogo}`,
                alt: "No logo image"
            },
            btnText : "View Website",
            btnLink : "",
            icons : [
                <i className="fa-brands fa-wordpress resume-card-icons" aria-label="Wordpress Icon" title="Wordpress"></i>,
                <i className="fa-brands fa-mailchimp resume-card-icons" aria-label="Mailchimp Icon" title="Mailchimp"></i>
            ]
        },
        {
            key: 6,
            name: "Self Employed",
            title: "Digital Marketer",
            location: "Atlanta, GA",
            length: "October 2017 to May 2018",
            description: "Created websites & marketing material for non-profits, friends, and family who gave me some of my first opportunities as a web developer.",
            image: {
                url:  `${NoLogo}`,
                alt: "No logo image"
            },
            btnText : "",
            btnLink : "",
            icons : [
                <i className="fa-brands fa-css3-alt resume-card-icons" aria-label="CSS3 Icon" title="CSS3"></i>,
                <i className="fa-brands fa-html5 resume-card-icons" aria-label="HTML5 Icon" title="HTML5"></i>,
                <i className="fa-brands fa-js resume-card-icons" aria-label="Javascript Icon" title="Javascript"></i>,
                <i className="fa-brands fa-mailchimp resume-card-icons" aria-label="Mailchimp Icon" title="Mailchimp"></i>,
                <i className="fa-brands fa-wordpress resume-card-icons" aria-label="Wordpress Icon" title="Wordpress"></i>,

            ]
        },
    ]

    return(
        <div className='resume-wrapper'>
            <h1 className='resume-header'>Résumé</h1>
            <div className='resume-container'>
                <div className='resume-left'>
                    <h3 className='resume-left-title'>
                        Makkiah Muhammad
                    </h3>
                    <h5 className='resume-left-location'>Columbus, Ohio</h5>
                    <h4 className='resume-left-profession'>Web Developer</h4>
                    <a className='resume-left-contact' href="#footer-contact">Contact Me</a>
                </div>
                <div className='resume-right'>
                    { jobInfo.map((job, index) => {
                        return (
                            <div className="resume-card" key={index}>
                                <div className="resume-card-left">
                                    {job.image.length !== 0 ? <img className='resume-card-img' src={`${job.image.url}`} alt={`${job.image.alt}`} key={index} /> : "No Image"}
                                </div>
                                <div className="resume-card-right">
                                    <h4 className='resume-card-title'>{job.name}</h4>
                                    <div className='resume-card-info'>
                                        <div className='resume-card-info-left'>
                                            <h5 className='resume-card-subtitle'>{job.title}</h5>
                                            <h5 className='resume-card-subtitle'>{job.location}</h5>
                                            <h5 className='resume-card-subtitle-length'>{job.length}</h5>
                                        </div>
                                        <div className='resume-card-info-right'>
                                            {job.icons}
                                        </div>
                                    </div>
                                    <p className='resume-card-desc'>{job.description}</p>
                                    {job.btnLink.length !== 0 ? <a href={`${job.btnLink}`} className='resume-card-link' target='_blank' rel="noreferrer">{job.btnText}</a> : ""}
                                </div>
                            </div>
                        );
                    } )}
                </div>
            </div>
        </div>
    )
}

export default ResumeComponent;