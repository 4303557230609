import {ContactPage} from '../../components/Contact/contact';
import Quote from '../../components/Quote/quote';

function Contact() {

    return (
      <>
        <Quote />
        <ContactPage/>
      </>
    );
  }
  
  export default Contact;