import './banner.scss';

function Banner(){

    const title = "Makkiah";
    const animatedText = ["Web Developer ","Guitarist ","Producer ","SMB Owner "];
    const subTitle = "A Creative Professional";

    return (
        <div className='banner-wrapper'>
            {/* <div className='banner-left'>
                No image
            </div> */}
            <div className='banner-middle'>
                <h1 className='banner-title'>{title}</h1>
                <div className="banner-textDiv">
                    {
                        animatedText.map((text, key) => {
                            return (
                                <p className='banner-animatedText'>
                                    {text}
                                    <i class="fa-solid fa-circle banner-animatedText-icon"></i>
                                </p>
                            )
                        })
                    }  
                </div>
                
                <h2 className='banner-subTitle'>{subTitle}</h2>
            </div>
        </div>
    )
}

export default Banner;