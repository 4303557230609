import './bio.scss';

const aboutMe = {
    shortBio : "Whether it's websites for businesses, or guitar riffs; creativity & building new things that serve a purpose undoubtedly feed my spirit.",
    regularBio : "Regular Biography",
    bioTitle : "Who Am I?",
    shortTitle : "In essence,",
    author : "Makkiah M."
};


function Bio() {
    return(
        <div className="bio-wrapper">
            <h1 className="short-bio-title">{aboutMe.bioTitle}</h1>
            <p className="short-bio-text">{aboutMe.regularBio}</p>
        </div>
    )
}


function ShortBio() {

    return ( 
    <div className="short-bio-wrapper">
        <h2 className="short-bio-title">{aboutMe.shortTitle}</h2> 
        <p className="short-bio-text">{aboutMe.shortBio}</p>
        <p className="short-bio-author">- {aboutMe.author}</p>
    </div>
    )
}

export {Bio, ShortBio}